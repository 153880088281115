.home-container {
    max-width: 1050px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .home-container h2 {
    color: #444;
    margin-bottom: 20px;
    text-align: center;
    font-size: 2em;
  }
  
.about-us-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-us-container h2 {
  color: #444;
  margin-bottom: 20px;
  text-align: center;
  font-size: 2em;
}

.about-us-container p {
  margin-bottom: 20px;
  color: #555;
}

.price-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
    background-color: #f4f4f4;
    max-width: 1000px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

.price-list-container h2{
    color: #444;
    margin-bottom: 20px;
    text-align: center;
    font-size: 2em;
    width: 100%;
  }
  
  .price-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(45% - 20px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    text-align: left;
  }
  
  .price-card:hover {
    transform: translateY(-5px);
  }
  
  .price-card h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 5px;
  }
  
  .service-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
  }
  
  .service-item span {
    font-size: 1em;
    color: #555;
  }
  
  .service-item span:last-child {
    font-weight: bold;
  }
  
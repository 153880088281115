/* Container Styles */
.container {
  padding-top: 80px; /* Adjust this value based on the height of your navbar */
  margin: 20px;
}

.side-by-side {
  display: flex;
  justify-content: center; /* Centers the components horizontally */
  align-items: flex-start; /* Aligns components at the start of the container */
  padding: 20px;
  gap: 20px; /* Adjusts the space between the components */
  height: 1000px;
  width: 1000px;
}

@media (max-width: 768px) {
  .main {
    padding-top: 240px;;
  }
}

  /* Navbar Styles */
.navbar {
  background-color: #333;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  color: #fff;
}

.navbar h1 {
  margin: 0;
  font-size: 1.5em;
}

.navbar ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  margin-right: 40px;
}

.navbar li {
  margin-left: 20px;
}

.navbar button {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.navbar button:hover {
  background-color: #fff;
  color: #333;
}

.navbar button:focus {
  outline: none;
}

@media (max-width: 768px) {
  .navbar {
      flex-direction: column;
      align-items: flex-start;
  }

  .navbar ul {
      flex-direction: column;
      width: 95%;
  }

  .navbar li {
      margin: 10px 0;
      width: 100%;
      text-align: left;
  }

  .navbar button {
      width: 100%;
  }
}

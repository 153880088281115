.contact-info-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-info-container h2 {
    color: #444;
    margin-bottom: 20px;
    text-align: center;
    font-size: 2em;
}

.contact-info-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.contact-info-list li {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    flex: 1 1 calc(45% - 20px); /* Responsive items */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    text-align: left;
}

.contact-info-list li:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.contact-info-list li strong {
    display: block;
    margin-bottom: 10px;
    color: #000;
    font-size: 1.2em;
}

@media (max-width: 768px) {
    .contact-info-list li {
        flex: 1 1 100%;
    }
}

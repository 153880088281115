/* src/components/Carousel.css */
.carousel .slide img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  
  .carousel .legend {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    font-size: 1.2rem;
  }
  